import * as React from "react"

function InstaIcon(props) {
	return (
		<svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.5065 7.06477C10.3909 7.06477 7.07123 10.3844 7.07123 14.5C7.07123 18.6156 10.3909 21.9352 14.5065 21.9352C18.622 21.9352 21.9417 18.6156 21.9417 14.5C21.9417 10.3844 18.622 7.06477 14.5065 7.06477ZM14.5065 19.3339C11.8469 19.3339 9.67259 17.1661 9.67259 14.5C9.67259 11.8339 11.8404 9.66613 14.5065 9.66613C17.1725 9.66613 19.3403 11.8339 19.3403 14.5C19.3403 17.1661 17.1661 19.3339 14.5065 19.3339ZM23.9801 6.76063C23.9801 7.72481 23.2035 8.49487 22.2458 8.49487C21.2816 8.49487 20.5116 7.71834 20.5116 6.76063C20.5116 5.80291 21.2881 5.02639 22.2458 5.02639C23.2035 5.02639 23.9801 5.80291 23.9801 6.76063ZM28.9045 8.52075C28.7945 6.19765 28.2639 4.13985 26.562 2.44444C24.8666 0.749024 22.8088 0.218398 20.4857 0.101919C18.0914 -0.033973 10.915 -0.033973 8.52075 0.101919C6.20411 0.211927 4.14632 0.742553 2.44444 2.43797C0.742552 4.13338 0.218398 6.19117 0.101919 8.51428C-0.033973 10.9086 -0.033973 18.085 0.101919 20.4792C0.211927 22.8024 0.742552 24.8601 2.44444 26.5556C4.14632 28.251 6.19764 28.7816 8.52075 28.8981C10.915 29.034 18.0914 29.034 20.4857 28.8981C22.8088 28.7881 24.8666 28.2574 26.562 26.5556C28.2574 24.8601 28.7881 22.8024 28.9045 20.4792C29.0404 18.085 29.0404 10.915 28.9045 8.52075ZM25.8114 23.0483C25.3066 24.3166 24.3295 25.2937 23.0547 25.8049C21.1458 26.562 16.616 26.3873 14.5065 26.3873C12.3969 26.3873 7.8607 26.5556 5.95821 25.8049C4.68989 25.3002 3.71276 24.323 3.20155 23.0483C2.44444 21.1393 2.61916 16.6096 2.61916 14.5C2.61916 12.3904 2.45091 7.85423 3.20155 5.95175C3.70629 4.68342 4.68342 3.70629 5.95821 3.19508C7.86717 2.43797 12.3969 2.61269 14.5065 2.61269C16.616 2.61269 21.1522 2.44444 23.0547 3.19508C24.323 3.69982 25.3002 4.67695 25.8114 5.95175C26.5685 7.8607 26.3938 12.3904 26.3938 14.5C26.3938 16.6096 26.5685 21.1458 25.8114 23.0483Z" fill="white"/>
		</svg>
	)
}

export default InstaIcon