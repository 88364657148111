import * as React from "react"

function TwitterIcon(props) {
	return (
		<svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M32.0359 7.22732C32.0586 7.54449 32.0586 7.86173 32.0586 8.1789C32.0586 17.8531 24.6954 29 11.2375 29C7.0914 29 3.23986 27.7992 0 25.7149C0.589084 25.7828 1.15543 25.8055 1.76718 25.8055C5.18822 25.8055 8.33749 24.65 10.8523 22.6789C7.63515 22.6109 4.93905 20.5039 4.01012 17.6039C4.46329 17.6718 4.91638 17.7172 5.39221 17.7172C6.04922 17.7172 6.7063 17.6265 7.31798 17.468C3.96486 16.7882 1.44994 13.843 1.44994 10.2859V10.1953C2.42412 10.7391 3.55703 11.0789 4.75772 11.1242C2.78663 9.8101 1.49527 7.56716 1.49527 5.02964C1.49527 3.67029 1.8577 2.42419 2.49212 1.33668C6.09448 5.77731 11.5094 8.67726 17.5812 8.9945C17.4679 8.45075 17.3999 7.8844 17.3999 7.31798C17.3999 3.28512 20.6624 0 24.7179 0C26.8249 0 28.728 0.883592 30.0648 2.31094C31.7186 1.99376 33.3046 1.38202 34.7093 0.543754C34.1655 2.24301 33.0101 3.67036 31.4921 4.57654C32.9648 4.41803 34.3921 4.01012 35.7062 3.44377C34.7094 4.89372 33.4633 6.18507 32.0359 7.22732Z" fill="white"/>
		</svg>
	)
}

export default TwitterIcon