import { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link } from 'react-router-dom';
import InstaIcon from '../Icons/InstaIcon';
import LinkedInIcon from '../Icons/LinkedInIcon';
import TwitterIcon from '../Icons/TwitterIcon';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const Footer = (props) => {
	const [form, setForm] = useState({email: '', interest: ''});
	const [loading, setloading] = useState(false);


	const onChange = (value, key) => {
		let currentForm = {...form};
		currentForm[key] = value;
		setForm(currentForm);
	}

	const onSubmit = () => {
		if (!form.email || !form.interest) {
			toast.error('Please fill in the required fields.')
			return
		}
		var templateParams = {
		    email: form.email,
		    subject: form.interest
		};
		setloading(true);

		emailjs.send('service_nkmcsme','template_wyudrik', templateParams, 'user_5zyw3hRLTMxa4wgw60Ps0')
    .then(function(response) {
    		toast('Thank you for contacting us.')
    		setloading(false)
    		setForm({email: '', interest: ''});
    }, function(err) {
       console.log('FAILED...', err);
    });
	}

	return (
		<footer>
			<Row>
				<Col md={6}>
					<h4>Get the latest news from the Burton & Downie team.</h4>
					<p>By clicking ‘sign up’, you agree to recieve emails in accordance with Burton & Downie’s <Link style={{color: '#fff'}} to="/privacy"><u>Privacy Policy</u></Link></p>
					<Row>
						<Col lg={7} md={11} className="fotterForm">
							<TextField 
								label="Enter your email address" 
								onChange={(e) => onChange(e.target.value, 'email')} 
								value={form.email}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={5} md={10}>
							<FormGroup>
								<Input 
									type="select" 
									name="select" 
									id="exampleSelect"
									onChange={(e) => onChange(e.target.value, 'interest')} 
									value={form.interest}
								>
									<option value="">I’M INTRESTED IN...</option>
									<option value="Commercial Management">Commercial Management</option>
									<option value="Brand Representation">Brand Representation</option>
									<option value="Business Development">Business Development</option>
									<option value="Partnership Brokerage">Partnership Brokerage</option>
									<option value="Advisory Services">Advisory Services</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Button disabled={loading} className="button_fotter" onClick={() => onSubmit()}>SIGN UP</Button>
						</Col>
					</Row>
				</Col>
				<Col md={6} className="footor_right">
					<h4>Stay connected with Burton & Downie.</h4>
					<Row>
						<Col md={12} lg={12}>
							<ul className="socialMedia">
								<li style={{marginRight: 40}}>
									<h4>FOLLOW US</h4>
								</li>
								<li>
									<a href="https://twitter.com/BurtonDownie" target="_blank">
										<TwitterIcon />
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/burtondownie/" target="_blank">
										<InstaIcon />
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/burton-downie" target="_blank">
										<LinkedInIcon />
									</a>
								</li>
							</ul>
						</Col>
					</Row>
					<hr className="color_white" />
					<Row>
						<Col md={6}>
							<p style={{marginBottom: 10}}><Link to="/privacy" style={{color: '#fff'}}><u>Privacy Policy</u></Link></p>
							<p><Link to="/consent" style={{color: '#fff'}}><u>Cookie & Consent Policy</u></Link></p>
						</Col>
					</Row>
					<Row className="text_bottom">
						<Col md={12} lg={12}>
							<h6 className="text_white" style={{fontSize: 18, position: 'relative', top: 30}}>Copyright © 2021Burton & Downie. All Rights Reserved</h6>
						</Col>
					</Row>
				</Col>
			</Row>
		</footer>
	)
}

export default Footer