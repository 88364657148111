import * as React from "react"

function ShakeHandIcon(props) {
	return (
		<svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.4" d="M0 28.125H5.625C6.37092 28.125 7.08629 27.8287 7.61374 27.3012C8.14118 26.7738 8.4375 26.0584 8.4375 25.3125V5.64258H0V28.125ZM4.21875 22.5088C4.49688 22.5088 4.76876 22.5913 5.00002 22.7458C5.23128 22.9003 5.41152 23.1199 5.51796 23.3769C5.62439 23.6339 5.65224 23.9166 5.59798 24.1894C5.54372 24.4622 5.40979 24.7127 5.21312 24.9094C5.01645 25.1061 4.76588 25.24 4.4931 25.2943C4.22031 25.3485 3.93756 25.3207 3.6806 25.2142C3.42364 25.1078 3.20402 24.9276 3.0495 24.6963C2.89498 24.4651 2.8125 24.1932 2.8125 23.915C2.8125 23.5421 2.96066 23.1844 3.22438 22.9207C3.4881 22.6569 3.84579 22.5088 4.21875 22.5088ZM40.1924 0.826174C39.9324 0.563963 39.6229 0.355929 39.282 0.214116C38.941 0.0723034 38.5753 -0.000472011 38.2061 2.30364e-06H30.6562C29.9534 0.000246325 29.2762 0.263629 28.7578 0.738284L20.1182 8.64844C20.1094 8.65723 20.1006 8.67481 20.0918 8.6836C19.4179 9.31367 19.0203 10.1846 18.9857 11.1065C18.9512 12.0285 19.2824 12.9267 19.9072 13.6055C21.0234 14.8272 23.3701 15.1523 24.8379 13.8428C24.8467 13.834 24.8643 13.834 24.873 13.8252L31.8955 7.3916C32.1721 7.14929 32.5325 7.02492 32.8996 7.04515C33.2668 7.06538 33.6114 7.22859 33.8596 7.49983C34.1079 7.77106 34.24 8.12874 34.2277 8.49622C34.2155 8.8637 34.0597 9.21176 33.7939 9.46582L31.5 11.5664L44.2969 21.9551C44.5453 22.1626 44.7774 22.3888 44.9912 22.6318V5.625L40.1924 0.826174Z" fill="white" />
			<path d="M47.8125 5.64258V25.3213C47.8125 26.0672 48.1088 26.7826 48.6363 27.31C49.1637 27.8375 49.8791 28.1338 50.625 28.1338H56.25V5.64258H47.8125ZM52.0312 25.3213C51.7531 25.3213 51.4812 25.2388 51.25 25.0843C51.0187 24.9298 50.8385 24.7101 50.732 24.4532C50.6256 24.1962 50.5978 23.9135 50.652 23.6407C50.7063 23.3679 50.8402 23.1173 51.0369 22.9207C51.2335 22.724 51.4841 22.5901 51.7569 22.5358C52.0297 22.4816 52.3124 22.5094 52.5694 22.6158C52.8264 22.7223 53.046 22.9025 53.2005 23.1338C53.355 23.365 53.4375 23.6369 53.4375 23.915C53.4375 24.288 53.2893 24.6457 53.0256 24.9094C52.7619 25.1731 52.4042 25.3213 52.0312 25.3213ZM42.5303 24.1436L29.4082 13.4912L26.7715 15.9082C25.5276 16.9961 23.9075 17.5543 22.2575 17.4634C20.6075 17.3726 19.0585 16.6399 17.9415 15.4219C16.8246 14.204 16.2285 12.5975 16.2804 10.9458C16.3324 9.29412 17.0284 7.72829 18.2197 6.58301L25.4092 0H18.0439C17.6748 0.000345637 17.3093 0.0734954 16.9685 0.215261C16.6277 0.357027 16.3181 0.564626 16.0576 0.826172L11.25 5.625V25.3037H12.8584L20.8125 32.502C21.97 33.442 23.4535 33.884 24.9368 33.7307C26.4201 33.5775 27.7818 32.8415 28.7227 31.6846L28.7402 31.667L30.3135 33.0293C30.6464 33.3002 31.0294 33.5028 31.4406 33.6255C31.8519 33.7482 32.2833 33.7887 32.7102 33.7447C33.1371 33.7006 33.5511 33.5728 33.9286 33.3686C34.3061 33.1644 34.6396 32.8879 34.9102 32.5547L37.6699 29.1621L38.1445 29.5488C38.7239 30.0184 39.4659 30.2386 40.2076 30.1612C40.9493 30.0837 41.6298 29.7149 42.0996 29.1357L42.9346 28.1074C43.4065 27.5281 43.629 26.7851 43.5532 26.0418C43.4774 25.2984 43.1094 24.6156 42.5303 24.1436Z" fill="#1A73E8" />
		</svg>
	)
}

export default ShakeHandIcon