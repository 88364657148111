import * as React from "react"

function SingleUserIcon(props) {
	return (
		<svg width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.4" d="M16.8179 36.4544L18.2812 30.2344L15.4688 25.3125H23.9062L21.0938 30.2344L22.5571 36.4544L19.6875 42.1875L16.8179 36.4544ZM19.6875 22.5C21.9125 22.5 24.0876 21.8402 25.9377 20.604C27.7877 19.3679 29.2297 17.6109 30.0811 15.5552C30.9326 13.4995 31.1554 11.2375 30.7213 9.05524C30.2873 6.87295 29.2158 4.86839 27.6425 3.29505C26.0691 1.72171 24.0646 0.650254 21.8823 0.216171C19.7 -0.217913 17.438 0.00487441 15.3823 0.85636C13.3266 1.70785 11.5696 3.14979 10.3335 4.99984C9.0973 6.84989 8.4375 9.02497 8.4375 11.25C8.4375 14.2337 9.62276 17.0952 11.7325 19.205C13.8423 21.3147 16.7038 22.5 19.6875 22.5Z" fill="white" />
			<path d="M28.1074 25.3652L19.6875 42.1875L11.2676 25.3652C5.00098 25.6641 0 30.7881 0 37.125V40.7812C0 41.9001 0.444474 42.9732 1.23564 43.7644C2.02681 44.5555 3.09987 45 4.21875 45H35.1562C36.2751 45 37.3482 44.5555 38.1394 43.7644C38.9305 42.9732 39.375 41.9001 39.375 40.7812V37.125C39.375 30.7881 34.374 25.6641 28.1074 25.3652Z" fill="#1A73E8" />
		</svg>
	)
}

export default SingleUserIcon