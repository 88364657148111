import * as React from "react"

function LinkedInIcon(props) {
	return (
		<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.49148 29H0.479029V9.63818H6.49148V29ZM3.48202 6.99705C1.55943 6.99705 0 5.40461 0 3.48202C1.37611e-08 2.55853 0.366855 1.67287 1.01986 1.01986C1.67287 0.366855 2.55853 0 3.48202 0C4.40551 0 5.29117 0.366855 5.94418 1.01986C6.59718 1.67287 6.96404 2.55853 6.96404 3.48202C6.96404 5.40461 5.40396 6.99705 3.48202 6.99705ZM28.9942 29H22.9947V19.5748C22.9947 17.3285 22.9494 14.4479 19.8687 14.4479C16.7427 14.4479 16.2637 16.8883 16.2637 19.413V29H10.2577V9.63818H16.0242V12.2793H16.1083C16.911 10.7581 18.8718 9.15268 21.7971 9.15268C27.8821 9.15268 29.0006 13.1597 29.0006 18.3643V29H28.9942Z" fill="white"/>
		</svg>
	)
}

export default LinkedInIcon