import { useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import Home from './container/Home';
import Privacy from './container/Privacy';
import Consent from './container/Consent';

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Routes(props) {

	return (
		<Router>
			<ScrollToTop />
			<Switch>
	      <Route path="/" exact component={Home} />
	      <Route path="/privacy" exact component={Privacy} />
	      <Route path="/consent" exact component={Consent} />
	    </Switch>
    </Router>
	)
}

export default Routes;