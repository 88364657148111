import { useState, useEffect } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import $ from 'jquery'; 

const Header = (props) => {
	const [isOpen, setIsOpen]             =   useState(false);
  const [activeClass, setActiveClass]   =   useState('home')

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    $(document).scroll(function () {
      $('section').each(function () {
          if(($(this).position().top - 40) <= $(document).scrollTop() && ($(this).position().top + $(this).outerHeight()) > $(document).scrollTop() - 40) {
              setActiveClass($(this).attr('id'))
            }
        });
    });
  },[])

  const goToMenu = (id) => {
    props.history.push('/')
    let node = document.getElementById(id);
    if (node) {
      node.scrollIntoView({ behavior: 'smooth' })
    }
    setIsOpen(false);
    setActiveClass(id);
  }

  const checkActive = (data) => {
    return activeClass == data ? true : false
  }

	return (
		<div className="header">
			<Navbar color="light" light expand="md">
        <NavbarBrand href="/">
        	<img src="/logo_white.png" alt="logo" className="imageHeader" />
        </NavbarBrand>
        <NavbarToggler type="reset" onClick={toggle} className="toggleMenu" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem active={checkActive('home')}>
              <NavLink onClick={() => goToMenu('home')}>Home</NavLink>
            </NavItem>
            <NavItem active={checkActive('service')}>
              <NavLink onClick={() => goToMenu('service')}>Services</NavLink>
            </NavItem>
            <NavItem active={checkActive('clients')}>
              <NavLink onClick={() => goToMenu('clients')}>Clients</NavLink>
            </NavItem>
            <NavItem active={checkActive('team')}>
              <NavLink onClick={() => goToMenu('team')}>Our Team</NavLink>
            </NavItem>
            <NavItem active={checkActive('contact')}>
              <NavLink onClick={() => goToMenu('contact')}>Contact Us</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
		</div>
	)
}

export default Header