import { useState, Fragment } from 'react';
import { Button, Container, Grid, TextField } from '@material-ui/core';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import CardIcon from '../components/Icons/CardIcon';
import ArrowIcon from '../components/Icons/ArrowIcon';
import UserChartIcon from '../components/Icons/UserChartIcon';
import SingleUserIcon from '../components/Icons/SingleUserIcon';

import OwlCarousel from 'react-owl-carousel';
import ListIcon from '../components/Icons/ListIcon';
import ShakeHandIcon from '../components/Icons/ShakeHandIcon';
import ChargeIcon from '../components/Icons/ChargeIcon';
import useWindowDimensions from '../components/widgets/useWindowDimensions';

const options = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true
};

const testimonial = [
	{
		message: "Dan's knowledge, insights, established network and most importantly passion, will ensure our brands are more accessible to the end consumer. We couldn’t ask for a better service and partnership.",
		name: "Neil Leamey", 
		post: "National Account Manager - Retail & Export, KBE Drinks"
	},
	{
		message: "Dan is an integral part of our business where his commercial philosophy, innovative approach, energy and relationship management skills have been vital in the success of our commercial programme and sustained growth",
		name: "David Moore", 
		post: "Finance Director, Wigan Warriors"
	},
	{
		message: "Dan has a unique and refreshing approach to what can be a very traditional market, where his ability to identify opportunities and understand clients’ needs to tailor win-win partnerships is second to none within the industry.",
		name: "Rory Stewart-Richardson", 
		post: "Founder & CEO, Connexi Partnerships "
	},
	{
		message: "It's been an enormous pleasure to work alongside a true commercial professional who just 'gets it'. We are opening up avenues we haven't explored yet which are proving to be successful and exciting for our business.",
		name: "Chris Yeo", 
		post: "General Manager - Commercial, Hurricanes "
	}
]

const Home = (props) => {
	const [form, setForm] = useState({first_name: '', last_name: '', email: '', mobile: '', subject: ''})
	const [loading, setloading] = useState(false);
	const [accepted, setAccepted] = useState(false);

	const { height, width } = useWindowDimensions()

	const onChange = (value, key) => {
		let currentForm = {...form};
		currentForm[key] = value;
		setForm(currentForm);
	}

	const onSubmit = () => {
		if (!form.first_name || !form.last_name || !form.email || !form.subject) {
			toast.error('Please fill in the required fields.')
			return
		}
		var templateParams = {
		    first_name: form.first_name,
		    last_name: form.last_name,
		    email: form.email,
		    mobile: form.mobile,
		    subject: form.subject
		};
		setloading(true);

		emailjs.send('service_nkmcsme','template_wyudrik', templateParams, 'user_5zyw3hRLTMxa4wgw60Ps0')
    .then(function(response) {
    		toast('Thank you for contacting us.')
    		setloading(false)
    		setForm({first_name: '', last_name: '', email: '', mobile: '', subject: ''});
    }, function(err) {
       console.log('FAILED...', err);
    });
	}

	const renderItemNo = () => {
		if (width > 900) {
			return 6
		} else if (width > 700) {
			return 4
		} else if (width > 500) {
			return 3
		} else if (width > 400) {
			return 2
		}
	}

	const renderPolicyPop = () => {
		let isAccepted = localStorage.getItem('isAccepted');
		if (!isAccepted && !accepted) {
			return <div className="privacyAccept">
				<h4>Your Privacy</h4>
				<p>We use cookies to personalise and improve yout experience on our site. Visit our <Link to="/privacy">privacy policy</Link> for more information on our data collection practices. By clicking Accept All Cookies, you agree to our use of cookies for the porpoises listed in our <Link to="/consent">cookie policy</Link>.</p>
				<p className="acceptLink">
					<a onClick={() => {setAccepted(true); localStorage.setItem('isAccepted', true)}}>Accept All cookies</a>
				</p>
			</div>
		}
	}

	const renderCompLogos = () => {
		return <Fragment>
			<div class='item'>
	    	<img src="images/Hurricanes-logo.png" />
	    </div>
	    <div class='item'>
	    	<img src="images/GB-Speedway-Team.png" />
	    </div>
	    <div class='item'>
	    	<img src="images/WW_CREST_PRIMARY_RGB.png" style={{width: 100}} />
	    </div>
	    <div class='item'>
	    	<img src="images/connexi.png" />
	    </div>
	    <div class='item'>
	    	<img src="images/KBE-Drinks-Logo.png" />
	    </div>
	    <div class='item'>
	    	<img src="images/phokus.png" />
	    </div>
	    <div class='item'>
	    	<img src="images/atpi.svg" style={{width: 170}} />
	    </div>
		</Fragment>
	}
	return (
		<Fragment>
			<Header {...props} />
			<section className="section" id="home">
				<Container className="topBanner">
					<div className="banner-caption">
						<h2>Transforming The Commercial Landscape</h2>
						<h5>Redefining How Brands & Rights Holders Engage <br />In The Sport & Entertainment Industry</h5>
					</div>
					{renderPolicyPop()}
				</Container>
			</section>
			<section id="service" className="section">
				{/* <span id="service" className="goToitem"></span> */}
				<Container>
					<div className="">
						<h3 className="heading">
							Services To Transform <br /> & Improve Your Partnerships
						</h3>
						<div className="servicesList">
							<ul>
								<li>
									<div className="ser_icon">
										<UserChartIcon />
									</div>
									<h5>Commercial Management</h5>
									<p>Lead on commercial <br />strategy and programme <br />implementation to drive <br />revenues </p>
									<div className="btm_link text-right arrow-icon-container">
										<a href=""><ArrowIcon /></a>
									</div>
								</li>
								<li>
									<div className="ser_icon">
										<SingleUserIcon />
									</div>
									<h5>Brand Representation</h5>
									<p>Lead on the identification, <br />development and acquisition <br />of strategic partnership <br />initiatives </p>
									<div className="btm_link text-right arrow-icon-container">
										<a href=""><ArrowIcon /></a>
									</div>
								</li>
								<li>
									<div className="ser_icon">
										<ListIcon />
									</div>
									<h5>Business Development</h5>
									<p>Data driven lead generation <br />to identify strategic <br />business development <br />opportunities  </p>
									<div className="btm_link text-right arrow-icon-container">
										<a href=""><ArrowIcon /></a>
									</div>
								</li>
								<li>
									<div className="ser_icon">
										<ShakeHandIcon />
									</div>
									<h5>Partnership Brokerage</h5>
									<p>Lead on partnership <br />acquisition programme <br />including sourcing and <br />negotiating partnerships</p>
									<div className="btm_link text-right arrow-icon-container">
										<a href=""><ArrowIcon /></a>
									</div>
								</li>
								<li>
									<div className="ser_icon">
										<ChargeIcon />
									</div>
									<h5>Advisory Services</h5>
									<p>Provide strategic <br />consultancy support to <br />maximise commercial <br />programme returns</p>
									<div className="btm_link text-right arrow-icon-container">
										<a href=""><ArrowIcon /></a>
									</div>
								</li>
							</ul>
						</div>
						<div className="service_slider">
							<OwlCarousel  className='owl-theme' loop margin={20} nav dots={false} items={renderItemNo()} autoplay autoplayTimeout={6000}>
						    {renderCompLogos()}
							</OwlCarousel>
						</div>
					</div>
				</Container>
			</section>
			<section id="clients" className="section">
				{/* <span id="clients" className="goToitem"></span> */}
				<Container>
					<div className="">
						<h3 className="heading">
							Our Clients & <br />What they Say about us
						</h3>
						<div className="service_slider testimonial_slider">
							<div className="quoteIcon">

							</div>
							<OwlCarousel className='owl-theme' loop margin={10} nav items={1}>
								{testimonial.map((item, index) => {
									return <div class='item' key={index}>
							      <h4>{item.message}</h4>
							      <h5><b>{item.name}</b>, {item.post}</h5>
							    </div>
								})}
							</OwlCarousel>
						</div>
						<hr className="sliderLine" />
						<div className="service_slider">
							<OwlCarousel className='owl-theme' loop margin={30} nav items={renderItemNo()} dots={false} autoplay autoplayTimeout={6000}>
						    {renderCompLogos()}
							</OwlCarousel>
						</div>
					</div>
				</Container>
			</section>
			<section id="team" className="section">
				{/* <span id="team" className="goToitem"></span> */}
				<Container>
					<div className="">
						<h3 className="heading">
							Get To Know Us <br />& The Team
						</h3>
						<div className="teamSection">
							<ul>
								<li>
									<div className="teamImg">
										<img src="images/dan.png" alt="dan" />
									</div>
									<h4>Dan Burton</h4>
									<h5>Managing Director</h5>
									<div className="teamDes">
										<p>A commercial specialist with over 15 years’ experience in the Sport, Entertainment and Media sectors within UK, Australia and New Zealand.</p>
										<p>Dan has held senior commercial positions in all levels of the sporting pyramid with experience working across Grassroots, Governing Bodies and Elite Professional Sport.</p>
										<p>Following 10 years within the sport and entertainment industry in his native New Zealand, Dan has lived in the UK since 2015 where he has held the role of Commercial Director at Wigan Warriors and been instrumental in the transformation of the clubs commercial programme.</p>
									</div>
								</li>
								<li>
									<div className="teamImg">
										<img src="images/team.png" alt="team" />
									</div>
									<h4>Gus Downie</h4>
									<h5>Strategic Director</h5>
									<div className="teamDes">
										<p>A strategy and data specialist with over 15 years’ experience in Technology, Digital Innovation, Data Management and Customer Journeys.</p>
										<p>An Alumni of both MIT and London Business School, Gus is an experienced Senior Director within the finance and banking industry with a background in software engineering, consultancy and project management.</p>
										<p>Leading on strategic advisory services, Gus is a specialist in the global roll-out of multi-million pound data and machine learning initiatives and new product development.</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</Container>
			</section>
			<section id="contact" className="section">
				{/* <span id="contact" className="goToitem"></span> */}
				<Container>
					<Grid container spacing={5}>
						<Grid item md={5}>
							<div className="contactInfo">
								<h3 className="heading">
									Reach out to see how <br />we can help you 
								</h3>
								<p>Please fill out the form to get in touch with us. You can also call or email us if you prefer.</p>
								<ul>
									<li>info@burtondownie.com</li>
									<li>+44 (0) 203 916 5183</li>
									<li>71-75 Shelton Street<br />
											Covent Garden, <br />
											London, <br />
											WCH 9JQ</li>
								</ul>
							</div>
						</Grid>
						<Grid md={1}>

						</Grid>
						<Grid item md={6}>
							<div className="contactForm">
								<TextField label="First Name*" onChange={(e) => onChange(e.target.value, 'first_name')} value={form.first_name} />
								<TextField label="Last Name*" onChange={(e) => onChange(e.target.value, 'last_name')} value={form.last_name} />
								<TextField label="Email*" onChange={(e) => onChange(e.target.value, 'email')} value={form.email} />
								<TextField label="Mobile" onChange={(e) => onChange(e.target.value, 'mobile')} value={form.mobile} />
								<TextField label="Subject*" multiline rows={4} onChange={(e) => onChange(e.target.value, 'subject')} value={form.subject} />
								<div className="text-right formBtn">
									<Button variant="contained" color="primary" onClick={() => onSubmit()} disabled={loading}>
									  SEND MESSAGE
									</Button>
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
				<div className="mapBox">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9079806071086!2d-0.1257385842297624!3d51.5149041796364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccab2ffaa9%3A0x9192a9c4dd80455b!2sCovent%20Garden!5e0!3m2!1sen!2sin!4v1620464480279!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
				</div>
			</section>
			<Footer />
		</Fragment>
	)
}

export default Home;